@import "../../styles/variables.module.scss";
.studentBeans{
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 9;
  background-color: #0dcaf0;
  padding: 12px;
  .studentBeansContainer{
    display: flex;
    position: relative !important;
    //justify-content: space-between;
    @include large_device{
      flex-direction: column;
      align-items: center;
    }
    .imageContainer{
      width: 259px;
      height: 30px;
      //padding-left: 2.75rem;
      @include large_device{
        padding-left: unset;
        width: 140px;
        height: 30px;
      }
    }
  }
  > span {
    padding: 2px !important;
  }
  p{
    font-weight: 500;
    margin: 8px 12px;
    padding-left: 2.4rem;
    @include large_device{
      text-align: center;
      padding-left: unset;
    }
  }
  .btn {
    width: auto;
    padding: 8px 12px;
    position: absolute;
    right: 12px;
    left: auto;
    @include large_device{
      position: unset;
      right: unset;
    }
  }
  .btnClose {
    width: auto;
    border: none;
    padding: 8px 12px;
    background-color: transparent;
    color: black;
    position: absolute;
    right: 0;
    left: auto;
    top: 10px;
    text-align: right;
  }
}
.topNavbar {
  padding-bottom: 12px;
  .navbarWithoutStudentBean{
    top: 0 !important;
  }
  .navbar {
    position: fixed;
    left: 0;
    right: 0;
    top: 64px;
    background: $white-color;
    z-index: 9;
    padding: 0;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    height: 60px;
    @include large_device {
      top: 160px;
    }
    @include extra_small {
      top: 178px;
    }
    @include iphone_xr {
      top: 160px;
    }

    .disNavRight {
      @include large_device {
        margin-right: 35px;
      }
      .btn {
        line-height: normal;
        width: auto;
        padding: 8px 12px;
      }
    }
  }
}
.navbarCollapse {
  @include large_device {
    position: fixed;
    left: -260px;
    background: $theme-color;
    width: 260px;
    height: 100vh !important;
    top: 0;
    bottom: 0;
    z-index: 99;
    opacity: 0;
    visibility: hidden;
    display: block !important;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    &[class*="show"] {
      opacity: 1;
      visibility: visible;
      width: 260px !important;
      left: 0;
      padding-bottom: 20px;
    }
    .mobileNav {
      height: calc(100vh - 95px);
      overflow-x: hidden;
      overflow-y: auto;
    }
  }
  .navbarNav {
    @include large_device {
      flex-direction: column;
      padding: 10px 0;
    }
    .navItem {
      .btn {
        background-color: transparent;
        border: none;
        padding: 0;
        width: 100%;
        text-align: left;
        & > .navLink {
          display: block;
        }
        &::after {
          content: none;
        }
        & + .dropdownMenu {
          .navLink {
            font-weight: 400;
            &[class*="btn"] {
              width: auto;
              background: transparent;
              text-align: left;
            }
          }
        }
      }
      .navLink {
        color: $theme-custom-color;
        font-weight: 500;
        font-size: 16px;
        padding: 16px 0;
        margin: 0 20px;
        position: relative;
        line-height: 48px;
        -webkit-transition: all 0.4s ease-in-out;
        transition: all 0.4s ease-in-out;
        @include up_large {
          margin: 0 6px;
        }
        @include large_device {
          margin: 0;
          padding: 0px 15px;
          color: #fff;
        }
        &:focus {
          -webkit-box-shadow: none;
          box-shadow: none;
        }
        .lbl {
          display: inline-block;
          margin-left: 5px;
          text-transform: capitalize;
          color: #07c451;
          font-weight: 400;
          line-height: 16px;
          border: 1px solid;
          border-radius: 50px;
          font-size: 10px;
          padding: 0 8px;
          vertical-align: text-top;
          @include extra_large_device {
            position: absolute;
            top: 0;
            right: 0;
          }
        }
      }
      .dropdownMenu {
        display: block !important;
        padding: 15px;
        opacity: 0;
        visibility: hidden;
        top: 90% !important;
        overflow: hidden;
        .navLink {
          border-bottom: none;
          padding: 0 10px;
          margin: 0;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-weight: 400;
          color: $theme-color;
          @include large_device {
            color: $white-color;
          }
        }
      }
      &:hover {
        .dropdownMenu {
          @include desktop_up {
            opacity: 1;
            visibility: visible;
            top: 100% !important;
          }
        }
      }
      &:first-child {
        .dropdownMenu {
          width: 800px;
          flex-wrap: wrap;
          padding: 20px;
          -webkit-column-count: 4;
          column-count: 4;
          @include large_device {
            -webkit-column-count: 1;
            column-count: 1;
          }
        }
      }
    }
  }
  [class^="dropdown"] {
    .btn {
      .navLink {
        @include desktop_up {
          &:before {
            content: "";
            background-image: url("../" + $bg-base-path + "/dropdown-toggle.svg");
            width: 10px;
            height: 10px;
            background-size: 10px;
            right: -16px;
            top: 50%;
            -webkit-transform: translateY(-50%) rotate(0deg);
            transform: translateY(-50%) rotate(0deg);
            position: absolute;
            background-repeat: no-repeat;
            background-position: center;
            -webkit-transition: all 0.3s ease-in-out;
            transition: all 0.3s ease-in-out;
          }
          &:hover {
            &::before {
              -webkit-transform: translateY(-50%) rotate(180deg);
              transform: translateY(-50%) rotate(180deg);
            }
          }
          @include up_large {
            margin-right: 25px;
          }
        }
      }
    }
  }
  .headerButton {
    .btn {
      padding: 9px 25px;
      width: auto;
      @include extra_large_device {
        padding: 8px 6px;
      }
      & + .btn {
        @include extra_large {
          margin-left: 10px;
        }
      }
    }
  }
}
.headerSidebarOpen {
  overflow: hidden;
  .navbarTogglerIcon {
    &[class*="open"] {
      display: none;
    }
  }
}
.sidebarOverlay {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(21, 21, 21, 0.8);
  z-index: 10;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  .navbarCollapse {
    position: fixed;
    left: -260px;
    background: $theme-color;
    width: 260px;
    height: 100vh !important;
    top: 0;
    bottom: 0;
    z-index: 99;
    opacity: 0;
    visibility: hidden;
    display: block !important;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    &[class*="show"] {
      opacity: 1;
      visibility: visible;
      width: 260px !important;
      left: 0;
    }
    .navbarNav {
      .navItem {
        width: 100%;
        background-color: rgba(0, 0, 0, 0);
        border: none;
        text-align: left;
        padding: 0;
        & > .navLink {
          margin: 0;
          padding: 0px 15px;
          color: #fff;
          display: block;
          line-height: 40px;
          text-overflow: initial;
          overflow: visible;
        }
        > &:first-child {
          background: #000;
          .dropdownMenu {
            .navItem {
              &:last-child {
                .navLink {
                  &::before {
                    content: "";
                    position: absolute;
                    width: 65%;
                    height: 1px;
                    background-color: $theme-color;
                    left: 10px;
                    bottom: 0;
                  }
                }
              }
            }
          }
        }
        &[class*="btn"] {
          &::after {
            content: "";
            position: absolute;
            background-image: url("../" + $bg-base-path + "/dropdown-toggle-w.svg");
            background-repeat: no-repeat;
            background-size: 12px;
            width: 12px;
            height: 8px;
            right: 15px;
            top: 50%;
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
            border: none;
          }
          &[class*="show"] {
            & + .dropdownMenu {
              display: block !important;
              opacity: 1;
              visibility: visible;
              margin: 5px 0;
              padding: 0 25px;
              width: 100%;
              background-color: rgba(0, 0, 0, 0);
              position: inherit !important;
              transform: translate3d(0px, 0px, 0px) !important;
              max-height: 100%;
              -webkit-box-shadow: none;
              box-shadow: none;
              column-count: 1;

              .navItem {
                &:active, &:hover, &:focus {
                  background-color: #24366b;
                }
               
                &:last-child {
                 &:active, &:hover, &:focus {
                  background-color: transparent;
                 }
                }
              }
            }
          }
        }
        &[class$="show"] {
          .navItem {
            &::after {
              -webkit-transform: rotateX(180deg);
              transform: rotateX(180deg);
              top: 40%;
            }
          }
        }
      }
    }
    .headerButton {
      position: fixed;
      bottom: 15px;
      left: 10px;
      right: 10px;
      margin: 0;
      z-index: 9999;
      a {
        width: 240px;
      }
      .loginActionBtn {
        position: fixed;
        bottom: 15px;
        left: 15px;
        right: 15px;
        margin: 0;
        z-index: 9999;
        width: 230px;
      }
    }
    .download {
      display: flex;
    }
  }
}
.navbarTogglerIcon {
  cursor: pointer;
  position: fixed;
  right: 10px;
  top: 180px;
  width: 30px;
  height: 20px;
  z-index: 9;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  @include extra_small{
    top: 200px;
  }
  @include iphone_xr{
    top: 180px;
  }
  &.studentBeansClose{
    top: 22px;
  }
  &.close {
    top: 28px;
    span {
      background-color: $white-color;
      &:first-child {
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
      }
      &:nth-child(2) {
        display: none;
      }
      &:nth-child(3) {
        top: 0;
        -webkit-transform: rotate(135deg);
        transform: rotate(135deg);
      }
    }
  }
  span {
    position: absolute;
    width: 25px;
    height: 2px;
    background: $theme-custom-color;
    top: 0;
    right: 0;
    &:first-child {
      top: 0;
    }
    &:nth-child(2) {
      top: 8px;
    }
    &:nth-child(3) {
      top: 16px;
    }
  }
}
